@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import url(http://fonts.googleapis.com/css?family=Open+Sans:400,700);

/* Override */
.show {
  display: block !important;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
  border-left: 1px solid #F1F3FA;
  border-right: 1px solid #F1F3FA;
}
.white.mb10 {
  margin-bottom: 10px;
}
a.kategorija {
  color: #3F3F3F;
}
.nav.nav-tabs.osposobljavanja {
  margin-bottom: 10px;
  background: #fff;
  border: none;
  display: flex;
  width: 100%;

  li {
    flex: 1;
    text-align: center;
  }

}
.heading-block-button {
  width: auto !important;
  margin: 0 !important;
  float: right;
  padding: 15px !important;
}
a.ispis {
  display: block;
  margin-bottom: 60px;
}
.capital {
  text-transform: uppercase;
}
.btn {
  border-radius:0;
}
.btn.full-width {
  display: block;
}
td.akcije {
  overflow: hidden;
  a {
    float: left;
    margin-right: 28px;
  }
  form {
    float: left;
    margin-right: 28px;
  }
}
.btn {
  margin-bottom: 10px;
}
.table > tbody > tr > td {
  vertical-align: middle;
}
#login_wrapper {
  input.form-control {
    outline: none;
    border: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
  }
}
.padding-left {
  padding-left: 25px !important;
}
label span {
  text-transform: uppercase;
}
input.form-control,textarea.form-control,select.form-control {
  font-size: 14px;
  font-weight: normal;
  padding: 6px;
  color: #333;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  box-shadow: none;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  border-radius: 0;
}
form button[type=submit].differnet {
  margin-top: 0;
  margin-bottom: 12px;
}
form button[type=submit] {
  color: #fff;
  border:none;
  padding: 8px;
  width: 100%;
  margin-top: 23px;
  background: #0073B7;
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  -ms-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;

  i {
    margin-right: 10px;
    font-size: 16px;
  }
}
form button[type=submit]:hover {
  background: #364150;
}
label {
  font-size: 13px;
  color: #525252;
}
.nomargin {
  margin: 0 !important;
}
.has-error, .has-error-input input, .has-error-input textarea {
  border: 1px solid #BE1F24 !important;
}



/* General */
$primary: #496CAC;

body {
  background: #F1F3FA;
  color: #3f3f3f;
  font-family: 'Open Sans', sans-serif;
}

#wrapper {
  min-height: calc(100vh - 177px);
}

.heading {
  border-bottom: 1px solid #c9cdd7;
  font-size: 18px;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

/* Navigation */
.navbar-top {
  background: #2B3643;
}
.navbar-top ul.nav li {
  display: inline-block;
}

.navbar-top.login {
  background: #242f3f;
}
.navbar-top-links a {
  color: #c6cfda;
  padding: 15px !important;
}

.navbar-top-links > li > a:hover, .navbar-top-links > li > a:focus, .navbar-top-links .open > a, .navbar-top-links .open > a:hover, .navbar-top-links .open > a:focus {
  background-color: #3f4f62;
}

#main_navigation {
  background-color: #364150;
  margin-bottom: 40px;

  ul {
    margin: 0;
    padding: 10px 0 10px 15px;

    li {
      display: inline-block;
      letter-spacing: normal;

      a {
        color: #c6cfda;
        padding: 10px 15px;
        font-size: 14px;
      }

      a:hover, a:focus {
        background-color: #3f4f62;
        text-decoration: none;
      }
    }

    li.active {
      a {
        background-color: #0073B7;
        color: #fff;
      }
    }

  }

}

/* Logo i zastavice */
img.logo {
  height: 25px;
}

.page-header {
  margin-bottom: 40px;
  border-bottom: 1px solid #cdcdcd;
}

/* Tiles */
.tile-title {
  position: relative;
  display: block;
  background: #303641;
  margin-bottom: 30px;
  -webkit-border-radius: 5px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 5px;
  -moz-background-clip: padding;
  border-radius: 5px;
  background-clip: padding-box;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  background: #0073b7;
}
.tile-title .icon {
  text-align: center;
  padding: 20px;
}
.tile-title.tile-big .icon {
  padding: 49px;

  i {
    font-size: 80px;
  }

}
.tile-title .icon i {
  font-size: 60px;
  line-height: 1;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  color: #fff;
}
.tile-title .title {
  background: #252a32;
  text-align: center;
  background: rgba(0, 0, 0, 0.1);
}
.tile-title .title h3 {
  padding-top: 20px;
  margin: 0;
  font-size: 14px;
  color: #fff;
}
.tile-title .title p {
  color: rgba(255, 255, 255, 0.85);
  padding-bottom: 20px;
  font-size: 11px;
}

.tile-title a:hover {
  text-decoration: none;

  .icon {
    background: rgba(0, 0, 0, 0.04);
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
  }

}

.tile-title.tile-black {
  background: #303641;

  .title {
    background: #252a32;;
  }
}

.tile-title.tile-black .title {
  background: rgba(0, 0, 0, 0.1);
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
  background: #0073B7;
  color: #fff;
}



/* Login */
#login_wrapper {
  -webkit-font-smoothing: antialiased;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: calc(100vh - 50px);
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-background-size: cover;
  background-size: cover;
  color: #fff;
  text-align: center;
  background: #303641;

  p,a {
    color: #BEC0C3;
  }

  a:hover {
    color: #fff;
    text-decoration: none;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
  }
}

#login {
  width: 320px;
  margin: 0 auto;
  text-align: center;
  font-size: 12px;

  img {
    margin-bottom: 40px;
  }

  .help-block {
    font-size: 12px;
    text-align: right;
    margin: 0;
  }


  .input-group {
    background: #373e4a;
    border: 1px solid #373e4a;
    padding-top: 6px;
    padding-bottom: 6px;
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;


  }

  .input-group:hover {
    border-color: #626f85;
    border-color: rgba(98, 111, 133, 0.5);
  }

  .input-group-addon {
    background: transparent;
    border:0;
    color: #919191;
    border-right:1px solid #454A54;
  }

  .form-control {
    background: transparent;
    border:0;
    font-size: 12px;
    color: #fff;
  }

  button[type=submit] {
    border: 1px solid #454a54;
    border-radius: 3px;
    text-align: left;
    padding: 15px 20px;
    font-size: 13px;
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    color: #ffffff;
    background-color: #303641;
    width: 100%;
    margin-bottom: 20px;

    i {
      float: right;
      font-size: 16px;
    }
  }
  button:hover {
      background: #373e4a;
  }
}

.custom_modal {

  .modal-content {
    border-radius:0;
  }

  .modal-dialog {
    width: 80% !important;
    border-radius: 0;

    .modal-header {
      background: #242F3F;
      text-align: left;
      padding: 0;

      button {
        color: #BEC0C3;
        opacity: 1;
        text-shadow: none;
        padding: 15px;
      }
      button:hover {
        color: #fff;
        -webkit-transition: all .5s;
        -moz-transition: all .5s;
        -ms-transition: all .5s;
        -o-transition: all .5s;
        transition: all .5s;
      }

      ul {
        margin: 0;
        padding: 0;
        height: 49px;

        li {
          line-height: 49px;

          a:hover,a:focus {
            text-decoration: none;
          }

        }

      }

    }
    .modal-body {
      h2{
        margin-top: 0;
        padding-top: 0;
      }
    }
    .tab-content {
      text-align: left;
      color: #646464;

      p {
        color: #646464 !important;
      }

    }
  }

}
a.blue.ispis_btn {
  color: #fff;
  background: $primary;
  padding: 15px;
  text-align: center;
  display: none;
}


/* Login nav */
ul.login_nav li {
  display: inline-block;
}

/* White Section */
.white {
  background: #fff;
  margin-bottom: 40px;

  .help-block {
    color: #BE1F24;
    font-size: 12px;
    float: right;
    margin: 0;
  }
.heading-block {
  margin:0;
  border-bottom:1px solid #EEEEEE;
  overflow: hidden;
  position: relative;

  h2 {
    font-size: 16px;
    margin: 0;
    padding: 15px;
    float: left;
  }
  a {
    float: right;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
    text-decoration: none;
  }
  a.blue, button.blue {
    color: #fff;
    background: $primary;
    padding: 15px 10px;
  }
  a.red {
    color: #fff;
    background: #D9534F;
    padding: 15px 10px;
  }
  a.green {
    color: #fff;
    background: #7FB955;
    padding: 15px 10px;
  }
  a:hover {
    background: #364150;;
  }
}
  .heading {
    margin:0;
    padding: 15px;
    border-color: #EEEEEE;
    font-size: 16px;
    overflow: hidden;
    position: relative;

    span {
      color: #fff;
      background: #364150;
      padding: 15px;
      position: absolute;
      top: 0;
      right: 0;
    }

    button {
      float: right;
    }
  }

  .content {
    padding: 15px;
    overflow-x: auto;
    min-height: 0.01%;
  }

}

ul.contact {
  padding: 0;

  li {
    list-style: none;
    margin-bottom: 5px;

    i {
      font-size: 16px;
      color: #fff;
      background: #0073B7;
      width: 30px;
      height: 30px;
      padding-top: 7px;
      text-align: center;
      margin-right: 10px;
    }
  }
}

.checkboxs .checkbox {
  border-bottom: 1px solid #eee;
  padding: 8px 15px;
  margin: 0;
}

.checkboxs .checkbox2 {
  border-bottom: 1px solid #eee;
  padding: 8px 30px;
  margin: 0;
}

.edited_white.white {
  margin-bottom: 11px;
}
.pitanja_white.white {
  .heading-block h2 {
    padding: 10px 0;
  }
  .content {
    margin-top: 0;
    padding: 5px 15px;
  }
  input {
    margin: 0 5px 5px 5px;
  }
}

.nav.nav-tabs.custom li a {
  text-transform: uppercase;
}

.table-bordered.ispit_table {
  border:none;
}
.table-bordered.ispit_table tr th {
  border-color: #F1F3FA !important;
  vertical-align: middle !important;
  font-size: 13px;
  padding: 5px 15px;
}
.table-bordered.ispit_table tr td {
  border-color: #F1F3FA !important;
  vertical-align: middle !important;
  font-size: 13px;
  padding: 5px 15px;
}
.table-bordered.ispit_table tr td input, .table-bordered.ispit_table tr td select {
  height: 30px;
  padding: 5px;
  margin-bottom: 5px;
  font-size: 13px;
}

.osposobljava {
  text-align: center;
  margin: 0 auto;

  span {
    text-transform: uppercase;
    display: block;
  }

  td {
    padding: 0 10px !important;
  }

  input {
    margin: 0;
    padding: 0;
    width: 20px;
  }
}

.ljecnici_pregledi {
  margin-top: 30px;
}

.mb {
  margin-bottom: 20px !important;
}

.staz {
  margin-top: 11px;
  margin-bottom: 0;
  font-size: 16px;
}

.remove_field, .btnOprema {
  display: block;
  margin-top: 0;
}

.checkboxCont {
  font-size: 15px;

  strong {
    position: relative;
    top: -7px;
    margin-right: 10px;
  }

  input[type="radio"], input[type="checkbox"] {
    width: 25px;
    height: 25px;
  }

}

.checkboxContV2 {
  font-size: 16px;
}

.mt {
  margin-top: 30px !important;
}

.searchCont {
  float: right;
  padding: 0;
}

form#search {

  .form-group {
    margin: 0;
  }

  input.form-control, textarea.form-control, select.form-control {
    border-top: none;
    border-bottom: none;
    height: 49px;
  }

}

/* Ispitivanja */
.nav.nav-tabs.ispitivanja {
  margin-bottom: 20px;
  background: #fff;
  border: none;
  display: flex;
  width: 100%;
  font-weight: 700;
  font-size: 13px;

  li {
    flex: 1;
    text-align: center;
  }

  .active a {
    color: #fff;
    background: #0073B7;
  }

}

.ispitivanjaRow {
  display: flex;
  text-align: center;
  background: #fff;
  position: relative;

  input[readonly] {
    cursor: not-allowed;
    background: #fff;
  }

}

.ispitivanjaRow--margintop {
  margin-top: 3px;
}

.ispitivanjaRow__stavka {
  display: flex;
  flex: 0.6;
  align-items: center;
  justify-content: center;
  border-right: 2px solid #F1F3FA;
  padding: 0 5px;
  font-weight: 700;
  font-size: 13px;
}

.ispitivanja_Row--input {
  align-items: flex-start;
  justify-content: center;

  input[type=text], span, textarea {
    width: 100%;
    border: none;
    height: 35px;
    font-size: 13px;
    font-weight: 400;
    background: #fff;
  }

  input[type=radio], input[type=checkbox] {
    text-align: center;
    margin-top: 15px;
  }

}


.ispitivanjaRow__stavka input {
  text-align: center;
}

.ispitivanjaRow__stavka:nth-child(1) {
  flex: 3;

  input {
    text-align: left;
  }
}

.ispitivanjaRow__stavka:nth-child(2) {
  flex: 0.5;
}

.ispitivanjaRow__stavka:nth-child(3) {
  flex: 0.85;
}

.ispitivanjaRow__stavka:nth-child(4) {
  flex: 0.8;
}

.ispitivanjaRow__stavka:nth-child(5) {
  flex: 0.7;
}

.ispitivanjaRow__stavka:nth-child(6) {
  flex: 0.4;
}

.ispitivanjaRow__stavka:nth-child(7) {
  flex: 0.95;
}

.ispitivanjaRow__stavka:nth-child(8) {
  flex: 1.7;

  input {
    text-align: left;
  }
}

.ispitivanjaRow__stavka:nth-child(9) {
  flex: 1.7;

  input {
    text-align: left;
  }
}

.ispitivanjaRow__stavka:nth-child(10) {
  flex: 2.5;

  input {
    text-align: left;
  }
}

.ispitivanjaRow__stavka:last-child {
  border-right: none;
}

.ispitivanjaRow--OdrzavanjaServis:nth-child(1) {
  flex: 2.5;
}

.ispitivanjaRow--OdrzavanjaServis:nth-child(2) {
  flex: 0.3;
}

.ispitivanjaRow--OdrzavanjaServis:nth-child(3) {
  flex: 0.4;
}

.ispitivanjaRow--OdrzavanjaServis:nth-child(4) {
  flex: 0.6;
}

.ispitivanjaRow--OdrzavanjaServis:nth-child(5) {
  flex: 0.5;
}

.ispitivanjaRow--OdrzavanjaServis:nth-child(6) {
  flex: 0.3;
}


.ispitivanjaRow--OdrzavanjaServis:nth-child(7) {
  flex: 0.6;
}

.ispitivanjaRow--OdrzavanjaServis:nth-child(8) {
  flex: 1.2;
}

.ispitivanjaRow--borderTop {
  border-top:1px solid #F1F3FA;
}

.ispitivanjaRow--periodickiPregledVa:nth-child(1) {
  flex: 0.4;

  input {
    text-align: center;
  }
}

.ispitivanjaRow--periodickiPregledVa:nth-child(2) {
  flex: 2.5;

  input {
    text-align: left;
  }
}

.ispitivanjaRow--periodickiPregledVa:nth-child(3) {
  flex: 0.6;

  input {
    text-align: left;
  }

}

.ispitivanjaRow--periodickiPregledVa:nth-child(4) {
  flex: 0.9;
}

.ispitivanjaRow--periodickiPregledVa:nth-child(5) {
  flex: 0.5;
}

.ispitivanjaRow--periodickiPregledVa:nth-child(6) {
  flex: 0.8;
}

.ispitivanjaRow--periodickiPregledVa:nth-child(7) {
  flex: 0.4;
}

.ispitivanjaRow--periodickiPregledVa:nth-child(8) {
  flex: 0.8;

  input {
    text-align: center;
  }
}

.ispitivanjaRow--periodickiPregledVa:nth-child(9) {
  flex: 2;

  input {
    text-align: center;
  }
}

.ispitivanjaRow--periodickiPregledVa:nth-child(10) {
  flex: 2;

  input {
    text-align: center;
  }
}

.ispitivanjaRow--periodickiPregledVa {

  .invert {
    flex-flow: row nowrap;
    align-items: center;
    align-content: stretch;
    width: 100%;
    flex: 1;
    padding: 0;

    .inside {
      //border-left:1px solid #F1F3FA;
      border-right: 1px solid #F1F3FA;
      display: flex;
      justify-content: center;
      flex: 1;
      min-width: 0;
      padding: 45px 0;
      width: 25%;
      float: left;
    }

    .inside:last-child {
      border-right: none;
    }

    span {
      text-align: center;
      white-space: nowrap;
      transform: rotate(-90deg);
      -webkit-transform: rotate(-90deg);
    }
  }

  input[type=checkbox] {
    flex: 1;
    align-self: center;
    width: 15px;
    height: 15px;
    margin-top: 4px;
  }
}

.ispitivanjaRow--redovniPregledVa:nth-child(1) {
  flex: 0.4;

  input {
    text-align: center;
  }
}

.ispitivanjaRow--redovniPregledVa:nth-child(2) {
  flex: 2.5;

  input {
    text-align: left;
  }
}

.ispitivanjaRow--redovniPregledVa:nth-child(3) {
  flex: 0.5;

  input {
    text-align: left;
  }

}

.ispitivanjaRow--redovniPregledVa:nth-child(4) {
  flex: 0.7;
}

.ispitivanjaRow--redovniPregledVa:nth-child(5) {
  flex: 0.5;
}

.ispitivanjaRow--redovniPregledVa:nth-child(6) {
  flex: 0.8;
}

.ispitivanjaRow--redovniPregledVa:nth-child(7) {
  flex: 0.8;
}

.ispitivanjaRow--redovniPregledVa:nth-child(8) {
  flex: 0.4;

  input {
    text-align: center;
  }
}

.ispitivanjaRow--redovniPregledVa:nth-child(9) {
  flex: 0.8;

  input {
    text-align: center;
  }
}

.ispitivanjaRow--redovniPregledVa:nth-child(10) {
  flex: 0.8;

  input {
    text-align: left;
  }

}

.ispitivanjaRow--redovniPregledVa:nth-child(11) {
  flex: 2;

  input {
    text-align: center;
  }
}


.ispitivanjaRow--redovniPregledVa {

  .invert {
    flex-flow: row nowrap;
    align-items: center;
    align-content: stretch;
    width: 100%;
    flex: 1;
    padding: 0;

    .inside {
      //border-left:1px solid #F1F3FA;
      border-right: 1px solid #F1F3FA;
      display: flex;
      justify-content: center;
      flex: 1;
      min-width: 0;
      padding: 45px 0;
      width: 25%;
      float: left;
    }

    .inside:last-child {
      border-right: none;
    }

    span {
      text-align: center;
      white-space: nowrap;
      transform: rotate(-90deg);
      -webkit-transform: rotate(-90deg);
    }
  }

  input[type=checkbox] {
    flex: 1;
    align-self: center;
    width: 15px;
    height: 15px;
    margin-top: 4px;
  }
}

.ispitivanja_Row--oprema {

  font-size: 13px;

  input[type=text], textarea {
    height: 50px;
    font-size: 12px;
  }

}

.ispitivanja_Row--oprema:nth-child(1){
  flex: 2.1;
}

.ispitivanja_Row--oprema:nth-child(2){
  flex: 1.3;
}

.ispitivanja_Row--oprema:nth-child(3){
  flex: 2.1;
}

.ispitivanja_Row--oprema:nth-child(4){
  flex: 0.75;
}

.ispitivanja_Row--oprema:nth-child(5){
  flex: 0.6;
}

.ispitivanja_Row--oprema:nth-child(6){
  flex: 0.25;
}

.ispitivanja_Row--oprema:nth-child(7){
  flex: 0.85;
}

.ispitivanja_Row--oprema:nth-child(8){
  flex: 1.3;
}

.ispitivanja_Row--oprema:nth-child(9){
  flex: 1.8;

  input {
    text-align: left;
  }
}

/* OPREMA NOVO */
.ispitivanja_Row--opremaNovo {

  font-size: 13px;

  input[type=text], textarea {
    height: 50px;
    font-size: 11px;
  }

}

.ispitivanja_Row--opremaNovo:nth-child(1){
  flex: 2.1;
}

.ispitivanja_Row--opremaNovo:nth-child(2){
  flex: 1.4;

  input {
    text-align: left;
  }
}

.ispitivanja_Row--opremaNovo:nth-child(3){
  flex: 1;

  input {
    text-align: left;
  }
}

.ispitivanja_Row--opremaNovo:nth-child(4){
  flex: 1.7;

  input {
    text-align: left;
  }
}

.ispitivanja_Row--opremaNovo:nth-child(5){
  flex: 0.7;
}

.ispitivanja_Row--opremaNovo:nth-child(6){
  flex: 0.5;
}

.ispitivanja_Row--opremaNovo:nth-child(7){
  flex: 0.3;
}

.ispitivanja_Row--opremaNovo:nth-child(8){
  flex: 0.7;

  input {
    text-align: center;
  }
}

.ispitivanja_Row--opremaNovo:nth-child(9){
  flex: 1.3;

  input {
    text-align: left;
  }
}

.ispitivanja_Row--opremaNovo:nth-child(10){
  flex: 1.3;

  input {
    text-align: left;
  }
}

.ispitivanja_Row--opremaNovo:nth-child(11){
  flex: 1.5;

  input {
    text-align: left;
  }
}

.ispitivanja_Row--postrojenje:nth-child(5){
  flex: 0.45;
}


.ispitivanja_Row--postrojenje:nth-child(7){
  flex: 0.6;
}

.ispitivanja_Row--postrojenje:nth-child(8){
  flex: 1.2;
}

.ispitivanja_Row--postrojenje:nth-child(9){
  flex: 1.2;
}

.ispitivanja_Row--postrojenje:nth-child(10){
  flex: 1.5;
}

.spojeno {
  flex-direction: column;

  input[type=text] {
    height: 25px;
  }

  input[type=text]:first-child {
    border-bottom: 1px solid #e0e2e9;
  }
}


.ispitivanja_Row--odrzavanja:nth-child(2) {
  flex: 0.5;
}

.dodajIspitivanje, .dodajUpisnika {
  margin-top: 23px;
  width: 100%;
}

.izrbisiIspitivanja {
  position: absolute;
  font-size: 16px;
  color: red;
  top: 13px;
  right: 5px;
}

.restoreIspitivanja {
  position: absolute;
  font-size: 16px;
  color: green;
  top: 13px;
  right: 20px;
}

.izrbisiIspitivanja:hover {
  cursor: pointer;
}

.ispitivanjaRow--postorjenje {
  .izrbisiIspitivanja {
    position: relative;
    left: 0;
    right: 0;
    top: 9px;
  }

  .restoreIspitivanja {
    position: relative;
    left: 0;
    right: 0;
    top: 9px;
  }
}


.ispitivanjaRow--postorjenje:nth-child(1) {
  flex: 0.2;

  input {
    text-align: center;
  }
}

.ispitivanjaRow--postorjenje:nth-child(2) {
  flex: 1.5;

  input {
    text-align: left;
  }
}

.ispitivanjaRow--postorjenje:nth-child(3) {
  flex: 1;

  input {
    text-align: left;
  }
}

.ispitivanjaRow--postorjenje:nth-child(4) {
  flex: 1;

  input {
    text-align: left;
  }
}

.ispitivanjaRow--postorjenje:nth-child(5) {
  flex: 1;

  input {
    text-align: left;
  }
}

.ispitivanjaRow--postorjenje:nth-child(6) {
  flex: 0.8;

  input {
    text-align: left;
  }
}

.ispitivanjaRow--postorjenje:nth-child(7) {
  flex: 0.3;
}

.ispitivanjaRow--postorjenje:nth-child(8) {
  flex: 0.2;
}



/* Type head */
.tt-menu {
  width: 100%;
  background: #fff;
  text-align: left;
}

.tt-suggestion {
  border-bottom: 1px solid #F1F3FA;
  padding: 10px 5px;
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  -ms-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
}

.tt-cursor, .tt-suggestion:hover {
  background: #0073B7;
  color: #fff;
  cursor: pointer;
}

/* Footer */
footer {
  background-color: #2B3643;
  padding: 15px 0;
  font-size: 13px;

  p {
    margin: 0;
    color: #C6CFDA;
  }

  img {

  }
}

.ui-corner-all.elfinder-buttonset:nth-child(3) {
  display: none !important;
}

.ui-corner-all.elfinder-buttonset:nth-child(6) {
  display: none !important;
}

.ui-corner-all.elfinder-buttonset:nth-child(7) {
   display: none !important;
}
//
//.ui-corner-all.elfinder-buttonset:nth-child(13) {
//  display: none !important;
//}

.ui-corner-all.elfinder-buttonset:last-child {
  display: none !important;
}

.ui-state-default.elfinder-navbar.ui-resizable {
  width: 330px !important;
}

.elfinder-workzone {
  height: 600px !important;
  background: #fff;
}

.remove_field {
  margin-top: 0;
}

#slika {
  max-width: 100%;
  overflow: hidden;
}

.file-btn {
  background-color: #189094;
  color: white;
  padding: 5px 10px;
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 13px;
  cursor: pointer;
  text-decoration: none;
  text-shadow: none;
  display: inline-block;
  cursor: pointer;
}

.file-btn input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}


.actions {
  position: relative;
}

.slika-cont #slika,
.slika-cont .upload-result,
.slika-cont.ready img.placeHolder {
  display: none;
}
.slika-cont.ready #slika {
  display: block;
}

.slika-cont .placeHolder {
  margin-bottom: 20px;
}